export const environment = {
  production: true,
  api: window['env']['api'] === '${API_URL}' ? 'https://api.infosindical.acesso.ws/rest' : window['env']['api'],
  searchApi: 'https://apitv.campolab.com.br/',
  apiLeiteBem: 'https://api-leitebem.sistemafaeg.com.br',
  oauthLeiteBem: 'https://id.sistemafaeg.org.br',
  apiPesebem: 'https://api-pesebem-adm.sistemafaeg.org.br/api/v1',
  talentos: 'https://api.talentosdocampo.com.br',
  version: 'V 6.2.5',
};
